$(document).ready(function () {

	// Cache selectors for scroll archors
	var lastId,
	    topMenu = $("header"),
	    menuItems = topMenu.find("a"),
	    scrollItems = menuItems.map(function(){
	      var item = $($(this).attr("href"));
	      if (item.length) { return item; }
	    });

	// Bind click handler to menu items
	menuItems.click(function(e){
		var href = $(this).attr("href"),
	    offsetTop = href === "#" ? 0 : $(href).offset().top+1;
	  	$('html, body').stop().animate({ 
	    	scrollTop: offsetTop
	  	}, 800);
	  	e.preventDefault();
	});
    $(window).scroll(function (event) {
		//Fade in animations
		var animatedEl = document.getElementsByClassName('animate');
		var h = window.innerHeight;
		var i;
		for (i = 0; i < animatedEl.length; i++) {
			var viewportOffset = animatedEl[i].getBoundingClientRect();
			var topPos = viewportOffset.top;
			if(topPos <= h*0.8){
				animatedEl[i].classList.add('fade-in');
			}
		}
	});
});